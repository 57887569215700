import React, { useEffect } from "react";
import { ReactQueryDevtools } from "react-query/devtools";
import styled from "styled-components";
import Navbar from "./Navbar";

const Layout = ({ children }) => {
  return (
    <>
      <Container>
        <Background>
          <BackgroundImage
            src="/images/shape_1.png"
            style={{
              top: "55vh",
              right: "67vw",
              mixBlendMode: "difference",
            }}
          />
          <BackgroundImage
            src="/images/shape_2.png"
            style={{ top: 75, right: 105, mixBlendMode: "difference" }}
          />
          <BackgroundImage
            src="/images/shape_3.png"
            style={{ top: 16, right: 16, mixBlendMode: "difference" }}
          />
        </Background>
        <Content>{children}</Content>
      </Container>
      <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
    </>
  )
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  background: ${props => props.theme.gradient1};
`

const Content = styled.div`
  width: 100%;
`

const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: ${props => props.theme.lightGrey};
`

const BackgroundImage = styled.img`
  position: absolute;
  z-index: 2;
`

export default Layout;
