import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"
import useWindowDimensions from "../hooks/useWindowDimensions"
import { GiHamburgerMenu } from "react-icons/gi"

const Navbar = () => {
  const [showHeader, setShowHeader] = useState(false)
  const [showDropdown, setShowDropdown] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const { height, width } = useWindowDimensions()

  const handleScroll = () => {
    if (typeof window !== "undefined") {
      const position = window.pageYOffset
      setScrollPosition(position)
    }
  }

  useEffect(() => {
    if (width > 1000) {
      setShowDropdown(true)
    } else {
      setShowDropdown(false)
    }
  }, [width])

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", handleScroll, { passive: true })
      return () => {
        window.removeEventListener("scroll", handleScroll)
      }
    }
  }, [])

  useEffect(() => {
    if (scrollPosition > 110) {
      setShowHeader(true)
    } else {
      setShowHeader(false)
    }
  }, [scrollPosition])

  function handleMobileMenu() {
    setShowDropdown(!showDropdown)
  }

  return (
    <NavWrapper>
      <AnimatePresence>
        {showHeader && (
          <AnimatedContainer showHeader={showHeader}>
            <ResponsiveContainer>
              <AnimatePresence>
                {showDropdown && (
                  <LinkContainer>
                    <StyledLink to="/replicas">Replicas</StyledLink>
                    <StyledLink to="/process">The Process</StyledLink>
                    <StyledLink to="/blogs">Blogs</StyledLink>
                    <StyledLink to="/faq">FAQ</StyledLink>
                    <StyledLink to="/terms-and-conditions">
                      Our Terms
                    </StyledLink>
                    <StyledLink to="/contact">Contact Us</StyledLink>
                  </LinkContainer>
                )}
              </AnimatePresence>
            </ResponsiveContainer>
          </AnimatedContainer>
        )}
      </AnimatePresence>
      <Container>
        <ResponsiveContainer>
          <LogoLink to="/">
            <Logo src="/black_logo.png" />
          </LogoLink>
          <HamburgerIcon onClick={handleMobileMenu} />
          <AnimatePresence>
            {showDropdown && !showHeader && (
              <LinkContainer>
                <StyledLink to="/replicas">Replicas</StyledLink>
                <StyledLink to="/process">The Process</StyledLink>
                <StyledLink to="/blogs">Blogs</StyledLink>
                <StyledLink to="/faq">FAQ</StyledLink>
                <StyledLink to="/terms-and-conditions">Our Terms</StyledLink>
                <StyledLink to="/contact">Contact Us</StyledLink>
              </LinkContainer>
            )}
          </AnimatePresence>
        </ResponsiveContainer>
      </Container>
    </NavWrapper>
  )
}

const NavWrapper = styled.div`
  z-index: 10000;
  width: 100%;
`

const StyledLink = styled(Link)`
  margin-left: 40px;
  padding: 35px 0;
  font: ${props => props.theme.normal};
  text-decoration: none;
  transition: all ease-in-out 0.3s;
`

const Container = styled.nav`
  width: 100%;
  height: 110px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;
  ${StyledLink} {
    color: ${props => props.theme.black};
    opacity: ${props => (props.showHeader ? 0.8 : 0.75)};
    &:hover {
      opacity: 1;
      color: ${props =>
        props.showHeader ? props.theme.lightGrey : props.theme.black};
    }
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    @media only screen and (max-width: 1000px) {
      color: ${props => props.theme.black};
      &:hover {
        opacity: 1;
        color: ${props => props.theme.lightGrey};
      }
    }
  }
  ${props => {
    if (props.showHeader) {
      return `
        position: fixed;
        top: 0;
        background-color: #fff;
      `
    }
    return `
      color: ${props => props.theme.linkColour};
    `
  }}
`

const AnimatedContainer = styled(motion(Container)).attrs(() => ({
  initial: "closed",
  exit: "closed",
  animate: "open",
  variants: {
    open: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.2 },
    },
    closed: {
      opacity: 0,
      y: -110,
      transition: { duration: 0.2 },
    },
  },
}))`
  box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
  z-index: 10;
`

const ResponsiveContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  max-width: 1140px;
`

const Logo = styled.img`
  height: 50px;
`

const LogoLink = styled(Link)``

const LinkContainer = styled(motion.div).attrs(() => ({
  initial: "closed",
  exit: "closed",
  animate: "open",
  variants: {
    open: {
      opacity: 1,
      x: 0,
      transition: { duration: 0.2 },
    },
    closed: {
      opacity: 0,
      x: 110,
      transition: { duration: 0.2 },
    },
  },
}))`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 1400px) {
    margin-right: 20px;
  }
  /* Hamburger */
  @media only screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    top: 90px;
    right: 0;
    position: absolute;
    background-color: ${props => props.theme.white};
    border-radius: ${props => props.theme.radius};
    width: 25%;
    min-width: 200px;
    max-width: 400px;
    box-shadow: 0px 0px 18px 1px rgb(0 0 0 / 10%);
  }
`

const HamburgerIcon = styled(GiHamburgerMenu)`
  display: none;
  color: ${props =>
    props.dark ? props.theme.darkLinkColour : props.theme.black};
  font-size: 30px;
  margin-right: 20px;
  cursor: pointer;
  @media only screen and (max-width: 1000px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

export default Navbar
