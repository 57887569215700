import React from "react"
import { QueryClient, QueryClientProvider } from "react-query"
import { ThemeProvider } from "styled-components"
import GlobalStyles from "./src/styles/globalStyles"
import { lightTheme } from "./src/styles/themes"
import * as Sentry from "@sentry/browser"
import { Integrations as TracingIntegrations } from "@sentry/tracing"
import Layout from "./src/components/Layout"

export const wrapPageElement = ({ element }) => {
  const queryClient = new QueryClient()

  Sentry.init({
    dsn:
      "https://e9cda338700d48f18340cbf5777801d9@o4505412679499776.ingest.sentry.io/4505412682317824",
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.2,
    release: "steel-skins@1.6.2",
  })

  return (
    <QueryClientProvider client={queryClient}>
      <meta charSet="utf-8" />
      <link
        rel="preload"
        href="/fonts/OCRAEXT.woff2"
        as="font"
        crossorigin="anonymous"
      />
      <link
        rel="preload"
        href="/fonts/TIDE_SANS_COND_100_LIL_BUNNY.woff2"
        as="font"
        crossorigin="anonymous"
      />
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap"
        rel="stylesheet"
      />
      <style>{`
        @font-face { 
          font-family: "OCR A Extended";
          src: url("/fonts/OCRAEXT.woff2");
        }
        @font-face { 
          font-family: "Tide Sans Cond 100 Lil Bunny";
          src: url("/fonts/TIDE_SANS_COND_100_LIL_BUNNY.woff2");
        }
      `}</style>
      <GlobalStyles />
      <ThemeProvider theme={lightTheme}>
        <Layout>{element}</Layout>
      </ThemeProvider>
    </QueryClientProvider>
  )
}
